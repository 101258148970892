import React from 'react'
import { AiOutlineClear } from "react-icons/ai";
import { useContext } from 'react'; 
import { GlobalStateContext } from '../../Context/GlobalStateContext';  
import { isMobile } from 'react-device-detect';
import { IoMdArrowRoundBack } from "react-icons/io";
const AimsNavbar = ({name}) => { 
  const {ClearChat,navigate ,setClearChat , get} = useContext(GlobalStateContext); 
  return (
    <nav className='dark:bg-slate-800 text-white flex md:ml-64 p-6 justify-between'>
   {!isMobile ? <p > Aims-Assistant </p>  : <IoMdArrowRoundBack  className='cursor-pointer ' onClick={()=>navigate('/TodayPatients')} size={25} />} 
      {get ? (
    <div onClick={() => setClearChat(prevState => !prevState)} className='flex gap-2 cursor-pointer'> 
    <p className='hidden md:block'>  Clear Chat</p>   
    <AiOutlineClear className='h-[22px] w-[25px]  text-white' />
    </div>
      ): (
      <div> 
        <p>Memory Full</p>
      </div> 
     )}

    <p> Dr {name.first_name} {name.last_name} </p>
    </nav>
  )
}

export default AimsNavbar
