import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../Components/sidebar.js';
import Navbar from '../Components/Navbar';
import Patient from "../Components/patients.js";
import Searchbar from '../Components/searchbar.js';
import axios from 'axios';   
import { Node_API_URL } from '../client';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStateContext } from '../Context/GlobalStateContext.js';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../features/auth/authSlice.js';
import TodayPatient from '../Components/TodayPatient/TodayPatient.js';  


const TodayPatients = (props) => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state)=> state.auth) 
  const options = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  const [isOpen, setIsOpen] = useState(false);  
  const [selectedOption, setSelectedOption] = useState("A");
  const [search ,setSearch] = useState("");
  const {token,checker,setUser , showToast} = useContext(GlobalStateContext)
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  const handleSearchChange = (event) => {
    setSearch(event.target.value); 
  }; 
  const handleOptionSelect = (option) => { 
    setSelectedOption(option);   
  };  
  const handleSearchClick = () => { 
    console.log(search);
  }
  const toggleOpen = () => {
    setIsOpen(prevState => !prevState); // Toggle the state
  };
  useEffect(() => {
    checker().then((res)=>{
      if(res==false)
      {
        setUser(false)
      dispatch(logout())
      navigate('/')
      }else{
        fetchData();
      }
    })
    const fetchData = async () => {
    setLoading(true);
      try {
        const config = {
          headers: {
              "Authorization": `Bearer ${token}`
          }
      }
        const res = await axios.get(`${Node_API_URL}/api/get/getTodayPatients?id=${localStorage.getItem('doc_id')}`,config);
        
        if(res.data.response === true){
          setPatients(res.data.patients); // Initialize with full data
        }
      } catch (error) {
        showToast("Error fetching patien reports",{type: 'error'});
      }finally{
      setLoading(false);
      }
    };

    
  }, []);


  return (
<div className="min-w-screen w-screen dark:bg-slate-900 ">
  <Navbar name={props.name} className="w-screen" /> {/* Ensure Navbar takes full width */}
  <div className='flex'>
    <div className='fixed h-full top-0 left-0 hidden md:block '>
      <Sidebar />
    </div>
    <div className='flex-1 md:ml-64 m-4 h-full flex flex-col items-center md:items-start md:w-full md:p-4 md:pt-12'>
  <div className='w-full max-w-full'> 

    {/* <Searchbar onSearch={handleSearch} /> */}
    <div className='w-full ' style={{ maxWidth: '95vw', overflowX: 'auto' }}>
      <div style={{ minWidth: '100%' }}>
        <TodayPatient patients={patients} setPatients={setPatients} loading={loading} />
      </div>
    </div>
  </div>
</div>



  </div>
</div>

  );
};

export default TodayPatients;
