
import React from 'react'
import MeetForm  from '../../Components/Meeting/Create/MeetForm.js'
const CreateMeet = () => {
  return (
    <>
    <MeetForm/>
    </>
  )
}

export default CreateMeet