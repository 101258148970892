import React, { useState } from 'react'
import Sidebar from "../Components/sidebar.js";
import Navbar from "../Components/Navbar";  
import AppointmentSetup from '../Components/Appointment/AppointmentSetup.js';
import Calendar from '../Components/Appointment/calander.js'; 
import AppointmentStatus from "../Components/Appointment/AppointmentStatus.js"; 
import AppointmentReport from '../Components/Appointment/AppointmentReport.js'; 
import {useEffect } from 'react';   
import Loader from '../Components/ui/Loader/Loader'
import { useContext } from 'react';  
import { GlobalStateContext } from '../Context/GlobalStateContext.js';
function AppoitmentSelection() { 
  const {setAppointmentRender , AppointmentRender} = useContext(GlobalStateContext);
  const [CalendarState,setCalendarState]=useState(false);   
  
  return (
    <div> 
     

    <Navbar  />

  <div className='flex dark:bg-slate-900 overflow-hidden'>
  <div className='fixed h-full top-0 left-0 hidden md:block '>
  <Sidebar />
</div>
    <div className='dark:bg-slate-900 relative md:ml-64  xl:flex flex-wrap justify-evenly mt-6 gap-2  h-full w-full'>
  
 
          <AppointmentReport/> 
          <div className=' xl:flex gap-1  xl:w-[calc(100vw-275px)]'>
        <div className='xl:w-[35%]' >
      <AppointmentSetup setCalendarState={setCalendarState}   />  
      </div> 
      {AppointmentRender && ( 

<div
  className="fixed inset-0 z-50 flex items-center  justify-center min-h-screen w-full"
  style={{ backgroundColor: 'rgba(31, 48, 55, 0.5)' }} 
>
  <Loader />
</div>



) }

  
 
      <div className='xl:w-[64.5%]'> 
        <Calendar CalendarState={CalendarState} />
      </div>    
      </div>
      <div className=''> 
        <AppointmentStatus/>
     
      </div>   
     
    <style jsx> 
      { 
        ` 
        .xl-flex-row-important {
  @apply xl:flex-row !important;
}
        `
      }
    </style> 
   
    </div>
  </div>
</div>
  )
}

export default AppoitmentSelection
