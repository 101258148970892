import React from 'react';
import Ceo from '../images/ceo.webp'
const Speaker = () => {
  return ( 
    <>
    {/* <div className="flex flex-col lg:flex-row items-center p-8 bg-white rounded-lg shadow-lg">
  
      <div className=" flex justify-center items-center w-2/3 lg:w-1/3">
        <img 
          src={Ceo} 
          alt="Speaker" 
          className="w-full h-auto object-cover rounded-lg"
        />
      </div>

      <div className=" mt-4 lg:mt-0 w-[95vw] lg:w-2/3 lg:ml-8">
      <div class="p-8 bg-gray-100 rounded-lg shadow-lg  w-[98%] lg:max-w-4xl mx-auto">
  <h1 class="text-4xl font-bold text-gray-800 mb-4">Speaker</h1>
  
  <p class="text-lg text-gray-600 mb-6">
    At AI Medical Scriber, we are revolutionizing healthcare documentation with our advanced AI technologies. 
    Our platform addresses two critical areas: medical note-taking and patient intake forms, freeing healthcare professionals from manual documentation.
  </p>

  <h2 class="text-2xl font-semibold text-gray-700 mb-3">Speech-to-Text AI for Medical Notes</h2>
  <p class="text-gray-600 mb-6">
    Our speech-to-text AI enables real-time transcription of medical notes as doctors speak naturally during patient consultations. 
    It is trained to understand complex medical terminology, ensuring accurate and structured notes without the need for manual input.
  </p>

  <h2 class="text-2xl font-semibold text-gray-700 mb-3">OCR for Patient Intake</h2>
  <p class="text-gray-600">
    Our OCR technology automates patient intake by extracting and organizing demographic information from forms. 
    This speeds up administrative processes, reduces errors, and allows healthcare staff to focus more on patient care.
  </p>
</div>
 
      </div> 
    </div>   */}
    <div className="flex flex-col lg:flex-row items-center  p-8 bg-white rounded-lg shadow-lg">
  {/* Left: Speaker Image */} 
  <div className="flex justify-center items-center w-2/3 lg:w-1/3">
    <img 
      src={Ceo} 
      alt="Speaker" 
      className="w-full h-auto object-cover rounded-lg"
    />
  </div>

  {/* Right: Documentary Text */}
  <div className="mt-4 lg:mt-0 w-[95vw] lg:w-2/3 lg:ml-8">
    <div className="p-8 bg-gray-100 rounded-lg shadow-lg w-[98%] lg:max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Speaker</h1>
      <h2 className="text-2xl font-semibold text-gray-700 mb-3">Intro</h2>
      <p className="text-lg text-gray-600 mb-6">
        At AI Medical Scriber, we are revolutionizing healthcare documentation with our advanced AI technologies. 
        Our platform addresses two critical areas: medical note-taking and patient intake forms, freeing healthcare professionals from manual documentation.
      </p>

      {/* <h2 className="text-2xl font-semibold text-gray-700 mb-3">Speech-to-Text AI for Medical Notes</h2>
      <p className="text-gray-600 mb-6">
        Our speech-to-text AI enables real-time transcription of medical notes as doctors speak naturally during patient consultations. 
        It is trained to understand complex medical terminology, ensuring accurate and structured notes without the need for manual input.
      </p>

      <h2 className="text-2xl font-semibold text-gray-700 mb-3">OCR for Patient Intake</h2>
      <p className="text-gray-600 mb-6">
        Our OCR technology automates patient intake by extracting and organizing demographic information from forms. 
        This speeds up administrative processes, reduces errors, and allows healthcare staff to focus more on patient care.
      </p> */}

      <h2 className="text-2xl font-semibold text-gray-700 mb-3">Upcoming AI Medical Scriber Event</h2>
      <p className="text-gray-600">
        We are excited to announce that we will be holding a conference where we will present AI Medical Scriber’s aims, features, and demonstrate its functionalities in detail. 
        You can join us by registering, and once the date is finalized, we will send you an email with the event details.
      </p>

      <p className="text-gray-600 mb-6">
        Our main target states for the event are:
        Miami, New York, California, Washington, Massachusetts, and Georgia.
      </p>
    </div>
  </div>
</div>

    {/* <div className='flex flex-col items-center lg:flex-row w-full justify-center bg-white p-8'>
      <div class=" w-[75vw] lg:w-[50vw] h-[50vh] flex items-center justify-center ">
      <iframe className='rounded-xl ' width="560" height="315" src="https://www.youtube.com/embed/x8RN1nunpMQ?si=4KFL5qwvBr5Br0FJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>  
  <div className='bg-gray-100 rounded-xl   w-[95vw] lg:w-[35vw] flex  p-4 lg:p-8 justify-center items-center'>
  <p class="mt-4 text-center text-lg text-gray-700 ">
    This video explores the transformative impact of technology in the medical field. From AI-powered diagnostics to innovations in telemedicine, 
    it highlights how advancements are revolutionizing patient care and healthcare management. Watch to learn more about the future of medical technology.
  </p> 
  </div>
<<<<<<< HEAD
  </div> 
  <div className='flex flex-col items-center lg:flex-row w-full justify-center bg-gray-100  p-8'>
      <div class=" w-[75vw]  lg:w-[50vw] h-[50vh]  flex items-center justify-center "> 

      <iframe width="560" height="315" src="https://www.youtube.com/embed/IG4E_ucLOSE?si=BvxqhRlltfHVe5qA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
=======
  </div>  */}
  <div className='flex flex-col   items-center lg:flex-row w-full justify-center bg-white  p-8'>
      <div class=" w-[75vw] lg:w-[50vw] h-[50vh] flex items-center justify-center ">
      <iframe className='rounded-xl ' width="560" height="315" src="https://www.youtube.com/embed/4Vra31ktXMk?si=0YZ9yHVaNco0Cb8X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>  
  <div  className='bg-gray-100 rounded-xl  w-[95vw] lg:w-[35vw] flex  p-4 lg:p-8 justify-center items-center'>
  <p class="mt-4 text-center text-lg text-gray-700 ">
    This video explores the transformative impact of technology in the medical field. From AI-powered diagnostics to innovations in telemedicine, 
    it highlights how advancements are revolutionizing patient care and healthcare management. Watch to learn more about the future of medical technology.
  </p> 
  </div>
  </div> 
    </>
  );
}

export default Speaker;
