import React from 'react'
import UpdatePatientForm from '../Components/Share/UpdatePatient'
const teste = () => {
  return (
    <>
    <UpdatePatientForm/>
    </>
  )
}

export default teste