import React from 'react'
import HomeNavBar from '../Components/Header/HomeNavBar'
import FeedBack from '../Components/FeedBack/FeedBack'
import Footer from '../Components/Footer/Footer'

const ContactPage = () => {
  return (
   <>
    <HomeNavBar/>
    <FeedBack/>
    {/* <Footer/> */}
   </>
  )
}

export default ContactPage