import React from 'react'
import Pricing from '../Components/Pricing'
import HomeNavBar from '../Components/Header/HomeNavBar.js'
import Footer from '../Components/Footer/Footer.js'

const pricePage = () => {
  return (
    <>

    <HomeNavBar />
    <Pricing/>
    {/* <Footer/> */}
    </>
  )
}

export default pricePage