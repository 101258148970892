import React from 'react';
import { Card, Typography , Tooltip } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoPatientSvg } from "../../images/NoPatientsYet.svg";
import { useState } from 'react';  
import axios from 'axios';
import { useContext } from 'react'; 
import { GlobalStateContext } from '../../Context/GlobalStateContext'; 
import { Node_API_URL } from '../../client'; 
import Loader from '../ui/Loader/Loader';
const TABLE_HEAD = ["#","Name", "Email", "Phone Number", "Edit"];  



const TodayPatient = ({ patients, setPatients , loading }) => {
  const navigate = useNavigate(); 
  const {token} = useContext(GlobalStateContext) 
  const options = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  const [isOpen, setIsOpen] = useState(false);  
  const [selectedOption, setSelectedOption] = useState("A");
  const [query1,setQuery1] = useState('')
  const [query2,setQuery2] = useState('')

  const handleRowClick = (patientId) => {
    navigate(`/PatientFich/${patientId}`);
  };
   
  const SearchByFilter = async (SearchType,query) => { 
    try { 
    const response = await axios.post(`${Node_API_URL}/api/post/searchPatientsByType`, 
      {
      type:SearchType,
      query:query
      }, 
      {
        headers: {
          "Authorization": `Bearer ${token}`,  
        },
      }
    ); 

    // if(response.data.response)
    // {
      setPatients(response.data.patients);
    // } 
    
  } catch (error) {
    console.error('Error while searching:', error);
  }


};  

  const SearchByAlphabet = async (alphabet) => { 
    setSelectedOption(alphabet);    
    
      try {
        const response = await axios.post(
          `${Node_API_URL}/api/post/searchPatientsByAlphabet`,
          {
            query: alphabet,  
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,  
            },
          }
        ); 

        if(response.data.response)
        {
          setPatients(response.data.patients);
        } 
      } catch (error) {
        console.error('Error while searching:', error);
      }


  }; 
  const toggleOpen = () => {
    setIsOpen(prevState => !prevState); // Toggle the state
  };
 
  return (
    <> 
    {/* <div className='flex justify-between flex-col p-4  md:p-8 mb-4 dark:bg-slate-800 items-center lg:flex-row'>  */}
    {/* <div class="flex w-[50vw] mb-3  md:w-[20vw]  items-center justify-between gap-2 bg-[#2f3640] rounded-full relative">
    <input  
        class="border-none bg-transparent outline-none text-white text-sm px-2 py-3 pl-4 md:px-6 md:py-6  md:pl-7" 
        type="text" 
        value={query1} 
        onChange={(e)=>setQuery1(e.target.value)} 
        placeholder="Search by name.." 
    />
    <button 
        class="absolute right-2 w-10 h-8 md:w-10 md:h-10 rounded-full bg-gradient-to-r from-[#2AF598] to-[#009EFD] border-0 hover:bg-[#1A1A1A] hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:-translate-y-1 transition-all duration-300 ease-[cubic-bezier(0.23,1,0.32,1)] active:shadow-none active:translate-y-0"
    onClick={() => SearchByFilter('name',query1)}
    > 
    
        <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_2_17)">
                <g filter="url(#filter0_d_2_17)">
                    <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"></path>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape"></feBlend>
                </filter>
                <clipPath id="clip0_2_17">
                    <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)"></rect>
                </clipPath>
            </defs>
        </svg>
    </button>
</div> 
<div class="flex w-[50vw]  md:w-[20vw]  mb-3  items-center justify-between gap-2 bg-[#2f3640] rounded-full relative">
    <input 
        class="border-none bg-transparent outline-none text-white text-sm px-2 py-3 pl-4 md:px-6 md:py-6  md:pl-7" 
        type="text" 
        value={query2} 
        onChange={(e)=>setQuery2(e.target.value)} 
        placeholder="Search by Email.." 
    />
    <button 
        class="absolute right-2 w-10 h-8 md:w-10 md:h-10 rounded-full bg-gradient-to-r from-[#2AF598] to-[#009EFD] border-0 hover:bg-[#1A1A1A] hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:-translate-y-1 transition-all duration-300 ease-[cubic-bezier(0.23,1,0.32,1)] active:shadow-none active:translate-y-0"
    onClick={() => SearchByFilter('email',query2)}
    > 
    
        <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_2_17)">
                <g filter="url(#filter0_d_2_17)">
                    <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"></path>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape"></feBlend>
                </filter>
                <clipPath id="clip0_2_17">
                    <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)"></rect>
                </clipPath>
            </defs>
        </svg>
    </button>
</div> */}
{/* <div
      className="relative inline-block text-left w-[50vw] md:w-[20vw]"
       onClick={toggleOpen} 
    >
      <div className="bg-gray-700 text-white px-4 py-2 rounded-full cursor-pointer flex justify-between items-center">
        <span>{selectedOption}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
          className={`w-4 h-4 transition-transform duration-300 ${isOpen ? 'rotate-0' : '-rotate-90'}`}
        >
          <path
            fill="white"
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
          />
        </svg>
      </div>

      {isOpen && (
      <div className="absolute left-0 mt-1 bg-gray-800 text-white rounded-md shadow-lg w-full z-10 transition-all duration-300 max-h-[50vh] overflow-y-auto">
      {options.map((option) => (
        <div 
          key={option}
          className="px-4 py-2 cursor-pointer hover:bg-gray-700 transition-all"
          onClick={() => SearchByAlphabet(option)}
        > 
          <p>{option}</p>   
        </div>
      ))}
    </div>
    
      )}
    </div> */}
       {/* </div>  */}
       <Card className="h-[100vh] w-full border-2 bg-[#FDFEE2] dark:bg-slate-800 border-blue-600 rounded-lg shadow-2xl p-6 dark:text-white">
       {loading ? (
        <div className="flex justify-center  items-center h-full">
          <Loader />
        </div>
      ) : (
        <>
  {patients.length > 0 ? (
    <div className="overflow-x-auto"> {/* Add overflow-x-auto here */}
      <table className="w-full table-auto text-left">
        <thead className="bg-blue-600 text-white">
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="p-4">
                <Typography variant="small" className="font-semibold uppercase">
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {patients.map((patient, index) => {
            const isLast = index === patients.length - 1;
            const classes = isLast ? "p-1" : "p-1 border-b border-blue-100";

            return (
              <tr
                key={index}
                className="hover:bg-blue-50 transition-colors cursor-pointer dark:hover:text-blue-500"
                ><td className={classes}>
                  {index+1}
                </td>
                <td className={classes}>
                  {patient.fullName}
                </td>
                <td className={classes}>
                  {patient.email}
                </td>
                <td className={classes}>
                  {patient?.phoneNumber ? patient?.phoneNumber : 'N/A'}
                </td>
                <td onClick={() => handleRowClick(patient._id)} className={classes}>
                  <Tooltip content="Edit Patient" placement="top">
                    Edit
                  </Tooltip>
                </td>
                
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
          ) : (
            <div className="flex flex-col items-center justify-center  h-[90vh]">
              <NoPatientSvg className="w-64 h-64 " />
              <Typography variant="h5" color="blue-gray" className="font-bold mt-3 text-2xl dark:text-white text-center">
                No patients have registered today yet.
              </Typography>
              {/* <Typography variant="body2" color="blue-gray" className="text-center mt-2">
                Start by adding new patients to see them listed here.
              </Typography> */}
            </div>
          )}
        </>
      )}
        </Card>
    </>
  );
};

export default TodayPatient;
