

import React from 'react'
import About from '../Components/About/About'
import HomeNavBar from '../Components/Header/HomeNavBar'
import Footer from '../Components/Footer/Footer'

const AboutPage = () => {
  return (
    <>
    <HomeNavBar/>
    <About/>
    <Footer/>
    </>
  )
}

export default AboutPage