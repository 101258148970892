/*eslint-disable*/
import React from 'react'
import RegisterPatient from '../../Components/Share/RegisterPatient'

const ShareForm = () => {
  return ( 
    <div className='w-full'>
    <RegisterPatient/> 
    </div>
  )
}

export default ShareForm